import React from 'react'
import Navbar from './components/Navbar'
import b1 from './images/b1.jpeg'

const Blog = () => {
  return (
    <div className='bg-slate-100 text-black'>
        <Navbar/>
        <div className='bg-gradient-to-tl to-[#a855f730] from-[#3b82f630] -mt-12'>
        <div className='max-w-[920px] m-auto px-4 md:px-0 p-28'>
            <h1 className='text-5xl font-bold gradient-text'>News & Blogs</h1>
            <p className='mt-5 w-[80%]'>we’re dedicated to supporting educational institutions with cutting-edge AI applications that enhance efficiency, performance, and academic integrity. Our innovative tools are designed to meet the diverse needs of universities, colleges, and language centers.</p>
        </div>
        </div>
      <main className=' max-w-[920px] m-auto px-4 md:px-0'>
      <div className='mt-20'>
    <h2 className='text-3xl font-bold text-purple-500'>Latest News</h2>
    <p className='text-sm mt-2 w-3/4'>Stay updated with the latest tips, trends, and insights in the world of writing. Explore our blog for expert advice, industry news, and more.</p>
    
    <div className=' grid md:grid-cols-4 grid-cols-1 gap-2 mt-5'>
    <div>
    <img src={b1} alt="" className='w-full h-48 rounded-md object-cover' />
    <p className='text-xs font-semibold mt-2'>August 25, 2024</p>
    <h3 className=' font-semibold'>10 Tips to Improve Your Writing Skills Today</h3>
    <p className='text-[10px]'>Discover practical tips and techniques that can help you enhance your writing skills. Whether you're a beginner or a seasoned writer, these strategies will take your writing to the next level.</p>
    <a href="/" className='text-sm text-blue-500'>Read More</a>
    </div>

    <div>
    <img src={b1} alt="" className='w-full h-48 rounded-md object-cover' />
    <p className='text-xs font-semibold mt-2'>August 25, 2024</p>
    <h3 className=' font-semibold'>10 Tips to Improve Your Writing Skills Today</h3>
    <p className='text-[10px]'>Discover practical tips and techniques that can help you enhance your writing skills. Whether you're a beginner or a seasoned writer, these strategies will take your writing to the next level.</p>
    <a href="/" className='text-sm text-blue-500'>Read More</a>
    </div>

    <div>
    <img src={b1} alt="" className='w-full h-48 rounded-md object-cover' />
    <p className='text-xs font-semibold mt-2'>August 25, 2024</p>
    <h3 className=' font-semibold'>10 Tips to Improve Your Writing Skills Today</h3>
    <p className='text-[10px]'>Discover practical tips and techniques that can help you enhance your writing skills. Whether you're a beginner or a seasoned writer, these strategies will take your writing to the next level.</p>
    <a href="/" className='text-sm text-blue-500'>Read More</a>
    </div>

    <div>
    <img src={b1} alt="" className='w-full h-48 rounded-md object-cover' />
    <p className='text-xs font-semibold mt-2'>August 25, 2024</p>
    <h3 className=' font-semibold'>10 Tips to Improve Your Writing Skills Today</h3>
    <p className='text-[10px]'>Discover practical tips and techniques that can help you enhance your writing skills. Whether you're a beginner or a seasoned writer, these strategies will take your writing to the next level.</p>
    <a href="/" className='text-sm text-blue-500'>Read More</a>
    </div>

    </div>
    </div>
      <div className='mt-20'>
    <h2 className='text-3xl font-bold text-purple-500'>Blogs</h2>
    <p className='text-sm mt-2 w-3/4'>Stay updated with the latest tips, trends, and insights in the world of writing. Explore our blog for expert advice, industry news, and more.</p>
    
    <div className=' grid md:grid-cols-4 grid-cols-1 gap-2 mt-5'>
    <div>
    <img src={b1} alt="" className='w-full h-48 rounded-md object-cover' />
    <p className='text-xs font-semibold mt-2'>August 25, 2024</p>
    <h3 className=' font-semibold'>10 Tips to Improve Your Writing Skills Today</h3>
    <p className='text-[10px]'>Discover practical tips and techniques that can help you enhance your writing skills. Whether you're a beginner or a seasoned writer, these strategies will take your writing to the next level.</p>
    <a href="/" className='text-sm text-blue-500'>Read More</a>
    </div>

    <div>
    <img src={b1} alt="" className='w-full h-48 rounded-md object-cover' />
    <p className='text-xs font-semibold mt-2'>August 25, 2024</p>
    <h3 className=' font-semibold'>10 Tips to Improve Your Writing Skills Today</h3>
    <p className='text-[10px]'>Discover practical tips and techniques that can help you enhance your writing skills. Whether you're a beginner or a seasoned writer, these strategies will take your writing to the next level.</p>
    <a href="/" className='text-sm text-blue-500'>Read More</a>
    </div>

    <div>
    <img src={b1} alt="" className='w-full h-48 rounded-md object-cover' />
    <p className='text-xs font-semibold mt-2'>August 25, 2024</p>
    <h3 className=' font-semibold'>10 Tips to Improve Your Writing Skills Today</h3>
    <p className='text-[10px]'>Discover practical tips and techniques that can help you enhance your writing skills. Whether you're a beginner or a seasoned writer, these strategies will take your writing to the next level.</p>
    <a href="/" className='text-sm text-blue-500'>Read More</a>
    </div>

    <div>
    <img src={b1} alt="" className='w-full h-48 rounded-md object-cover' />
    <p className='text-xs font-semibold mt-2'>August 25, 2024</p>
    <h3 className=' font-semibold'>10 Tips to Improve Your Writing Skills Today</h3>
    <p className='text-[10px]'>Discover practical tips and techniques that can help you enhance your writing skills. Whether you're a beginner or a seasoned writer, these strategies will take your writing to the next level.</p>
    <a href="/" className='text-sm text-blue-500'>Read More</a>
    </div>

    </div>
    </div>
      </main>
      <footer className='bg-white text-center p-3 mt-20'>
        <p className='text-slate-400'>© 2024 Appverse Tech Inc. All rights reserved.</p>
      </footer>
    </div>
  )
}

export default Blog
