import React from 'react'
import Navbar from './components/Navbar'
import p1 from './images/p1.jpg'
import p2 from './images/p2.jpg'
import p3 from './images/p3.jpg'
import p4 from './images/p4.jpg'
import p5 from './images/p5.jpg'
import bi from './images/bi.jpg'
import About from './images/about.jpg'
import why from './images/whyus.jpg'
import gr from './images/gr.webp'
import gr1 from './images/gr1.webp'
import ail from './images/ail.webp'
import b1 from './images/b1.jpeg'
import bnr from './images/bnr.jpg'

const Home = () => {
  return (
    <div className=' bg-slate-100 text-black'>
        <Navbar/>
      <main className=' max-w-[920px] m-auto px-4 md:px-0'>
        <div className='my-28'>
            <h1 className='flex items-center flex-col gap-2'>
                <span className=' text-6xl font-bold'>Welcome To</span>
                <span className='md:text-8xl text-6xl font-bold gradient-text text-center'>Appverse Tech</span>
                <span className=' md:text-5xl text-2xl font-bold text-center'>we’re dedicated</span>
            </h1>
            <p className=' italic text-base text-center my-5 text-slate-500'>to supporting educational institutions with cutting-edge AI applications that enhance efficiency, performance, and academic integrity. Our innovative tools are designed to meet the diverse needs of universities, colleges, and language centers.</p>
            <div className='flex items-center gap-2 justify-center my-10'>
                <a href='#Features' className=' bg-blue-500 bg-opacity-20 border border-blue-500 py-2 px-4 rounded-md font-semibold'>Explore Features</a>
                <a href='#Tool' className=' bg-purple-500 bg-opacity-20 border border-purple-500 py-2 px-4 rounded-md font-semibold'>Get the tools you need.</a>
            </div>
            <div className='flex items-center gap-2 justify-center mt-5'>
            <p>Excellent</p>
            <div className='flex items-center'>
                <img src={p1} alt="" className='w-8 h-8 object-cover rounded-full border-[3px] border-white' />
                <img src={p2} alt="" className='w-8 h-8 object-cover rounded-full border-[3px] -ml-4 border-white' />
                <img src={p3} alt="" className='w-8 h-8 object-cover rounded-full border-[3px] -ml-4 border-white' />
                <img src={p4} alt="" className='w-8 h-8 object-cover rounded-full border-[3px] -ml-4 border-white' />
                <img src={p5} alt="" className='w-8 h-8 object-cover rounded-full border-[3px] -ml-4 border-white' />
            
            </div>
            <p>Trusted by <span className=' bg-slate-600 text-white px-2 rounded-md font-medium'><i class="ri-bard-fill"></i> 550</span> Active Users</p>
            </div>
        </div>

        <div className='bg-gradient-to-tl to-purple-500 from-blue-500 rounded-xl p-8'>
        <h2 className='text-2xl font-bold text-white'>Empowering Education with <br />Advanced Features</h2>
        <p className='text-white mt-4 text-sm'>We offer a suite of AI applications tailored to optimize academic and administrative processes. Our flagship tools include the AI Writer and AI Grammar Assistant, complemented by essential features like plagiarism detection, sales performance metrics, and passive voice detection.</p>
        </div>

        <div className='mt-20' id='Features'>
      <h2 className='text-3xl font-bold text-purple-500'>Explore Our Powerful Features</h2>
      <p className='text-sm mt-2 w-3/4'>Discover a suite of innovative tools designed to enhance every aspect of your writing, from professional documents to creative stories and academic research.</p>
      <div className='grid md:grid-cols-3 grid-cols-1 gap-5 mt-5'>
        <div className='bg-slate-200 border border-slate-400 hover:bg-gradient-to-tl from-blue-500 to-purple-500 hover:text-white p-4 rounded-lg'>
          <div className='bg-blue-200 border border-blue-500 text-blue-500 flex items-center justify-center rounded-full w-10 h-10 text-xl'>
        <i class="ri-pencil-ruler-2-fill"></i>
          </div>
        <h3 className='text-lg font-bold mt-2'>Advanced Grammar Check</h3>
        <p>Ensure your writing is flawless with real-time grammar and punctuation corrections, making every document professionally polished.</p>
        </div>
        
        <div className='bg-slate-200 border border-slate-400 hover:bg-gradient-to-tl from-blue-500 to-purple-500 hover:text-white p-4 rounded-lg'>
          <div className='bg-blue-200 border border-blue-500 text-blue-500 flex items-center justify-center rounded-full w-10 h-10 text-xl'>
          <i class="ri-rocket-fill"></i>
          </div>
        <h3 className='text-lg font-bold mt-2'>AI-Powered Content Generation</h3>
        <p>Generate high-quality content quickly with our AI-driven suggestions and templates, designed to boost your productivity and creativity.</p>
        </div>

        <div className='bg-slate-200 border border-slate-400 hover:bg-gradient-to-tl from-blue-500 to-purple-500 hover:text-white p-4 rounded-lg'>
          <div className='bg-blue-200 border border-blue-500 text-blue-500 flex items-center justify-center rounded-full w-10 h-10 text-xl'>
          <i class="ri-check-double-fill"></i>
          </div>
        <h3 className='text-lg font-bold mt-2'>Plagiarism Detection</h3>
        <p>Maintain originality with our comprehensive plagiarism checker that scans your work against a vast database to ensure your content is unique.</p>
        </div>

        <div className='bg-slate-200 border border-slate-400 hover:bg-gradient-to-tl from-blue-500 to-purple-500 hover:text-white p-4 rounded-lg'>
          <div className='bg-blue-200 border border-blue-500 text-blue-500 flex items-center justify-center rounded-full w-10 h-10 text-xl'>
          <i class="ri-artboard-2-fill"></i>
          </div>
        <h3 className='text-lg font-bold mt-2'>Customizable Templates</h3>
        <p>Access a wide range of templates for every writing need, and customize them to fit your unique style and requirements.</p>
        </div>

        <div className='bg-slate-200 border border-slate-400 hover:bg-gradient-to-tl from-blue-500 to-purple-500 hover:text-white p-4 rounded-lg'>
          <div className='bg-blue-200 border border-blue-500 text-blue-500 flex items-center justify-center rounded-full w-10 h-10 text-xl'>
          <i class="ri-lightbulb-fill"></i>
          </div>
        <h3 className='text-lg font-bold mt-2'>Style and Tone Adjustment</h3>
        <p>Enhance your writing style and adjust the tone to match your intended audience or purpose, ensuring effective communication.</p>
        </div>

        <div className='bg-slate-200 border border-slate-400 hover:bg-gradient-to-tl from-blue-500 to-purple-500 hover:text-white p-4 rounded-lg'>
          <div className='bg-blue-200 border border-blue-500 text-blue-500 flex items-center justify-center rounded-full w-10 h-10 text-xl'>
          <i class="ri-voice-recognition-fill"></i>
          </div>
        <h3 className='text-lg font-bold mt-2'>Integration and Collaboration</h3>
        <p>Seamlessly integrate with other tools and collaborate with team members to streamline your writing projects and workflows.</p>
        </div>

      </div>
    </div>

    <div id='about' className='grid md:grid-cols-2 grid-cols-1 gap-10 mt-20 items-center'>
    <div>
    <h2 className='text-3xl font-bold text-purple-500 mb-5'>About Us</h2>
    <p className='text-sm'>At Appverse Tech, our mission is to transform the way people approach writing. Whether you’re a professional drafting important documents, a creative weaving intricate stories, or an academic seeking precision in research, our platform is designed to meet your needs. We believe that great writing should be accessible to everyone, and that's why we've harnessed the power of artificial intelligence to create tools that are intuitive, powerful, and easy to use. Our commitment to innovation and user satisfaction drives us to continuously improve and expand our offerings, ensuring that you have the best possible resources to achieve your writing goals.</p>
    <ul className=' list-disc ml-5 mt-5'>
    <li className='text-xs'>Ai writer- Easy Text Creation app. The illustration. The Ai Grammar Assistant you have it.</li>
    <li className='text-xs mt-2'>The Ai Grammar Assistant has a subscription, grammar correction and voice to text feature.</li>
    </ul>
    <a href="/" className=' font-semibold mt-5 block hover:text-blue-500'>Learn More About Us<i class="ri-arrow-right-line"></i></a>
    </div>
    <div>
      <img src={About} alt="" className=' rounded-lg w-full h-[23rem] object-cover' />
    </div>
    </div>

    <div className='mt-20'>
    <img src={bnr} alt="" className='w-full rounded-xl' />
    </div>

        <div className='mt-20' id='Tool'>
      <h2 className='text-3xl font-bold text-purple-500'>Powerful Tools for Every Writing Need</h2>
      <p className='text-sm mt-2 w-3/4'>Utilize our specialized tools designed to make writing, editing, and reviewing easier and more effective. Perfect for professionals, creatives, and academics alike.</p>
      <div className='grid md:grid-cols-2 grid-cols-1 gap-5 md:mt-5 mt-[22rem]'>
        <div className='bg-slate-200 h-96 mt-16 md:col-span-2 grid md:grid-cols-2 grid-cols-1 group border border-slate-400 hover:bg-gradient-to-tl from-blue-500 to-purple-500 hover:text-white p-4 rounded-lg'>
          <div className=' relative'>
          <img src={gr} alt="" className=' h-96 w-48 object-cover rounded-md border-2 border-slate-600 absolute bottom-10 left-10' />
          <img src={gr1} alt="" className=' h-96 w-48 object-cover rounded-md border-2 border-slate-600 absolute bottom-0 right-10' />
          </div>
          <div>
        <h3 className='text-lg font-bold mt-2'>AI Grammar Assistant App</h3>
        <p>Write and edit documents anywhere with our AI-powered Writing Assistant. Get real-time grammar checks, style suggestions, and content enhancement right on your mobile device. <br />
        <b>Ensure Accuracy:</b> Advanced grammar and style checks help produce polished, error-free text. <br />
        <b>Improve Writing Skills:</b> Identify and correct grammatical mistakes to enhance writing habits and language proficiency. <br />
        <b>Support Diverse Needs:</b> Suitable for both native and non-native speakers, ensuring high-quality written content.

        </p>
        <a href="https://apps.apple.com/us/app/ai-grammar-assistant/id6479434692" className='bg-purple-500 bg-opacity-15 py-2 px-4 font-semibold rounded-md group-hover:bg-white group-hover:text-black mt-5 block w-max border border-purple-500'>Get The App!</a>
          </div>
        </div>
        
        <div className='bg-slate-200 border group border-slate-400 hover:bg-gradient-to-tl from-blue-500 to-purple-500 hover:text-white p-4 rounded-lg'>
          <div className=''>
          <img src={ail} alt="" className=' h-20 w-20 object-cover rounded-md border-2 border-slate-600' />
          </div>
        <h3 className='text-lg font-bold mt-2'>AI Writer - Easy Text Creation App</h3>
        <p>The AI Writer- subscription base, available in 27 different languages giving
        global reach.</p>
        <p><b>Simplify Content Creation:</b> Quickly generate high-quality text for academic papers, research articles, and educational materials with ease. <br />
        <b>Boost Productivity:</b> Save time on content creation, allowing educators and students to focus more on critical academic activities. <br />
        <b>Enhance Creativity:</b> Assist in brainstorming and developing content ideas, providing a creative boost for writing and course development.
        </p>
        <a href="https://apps.apple.com/us/app/ai-writer-easy-text-creation/id6470300071" className='bg-purple-500 bg-opacity-15 py-2 px-4 font-semibold rounded-md group-hover:bg-white group-hover:text-black mt-5 block w-max border border-purple-500'>Get The App!</a>
        </div>

        <div className='bg-slate-200 border group border-slate-400 hover:bg-gradient-to-tl from-blue-500 to-purple-500 hover:text-white p-4 rounded-lg'>
          <div className=''>
          <img src={bi} alt="" className=' h-20 w-20 object-cover rounded-md border-2 border-slate-600' />
          </div>
        <h3 className='text-lg font-bold mt-2'>AI-Powered Content Generation</h3>
        <p>Generate high-quality content quickly with our AI-driven suggestions and templates, designed to boost your productivity and creativity.</p>
        <a href="/" className='bg-purple-500 bg-opacity-15 py-2 px-4 font-semibold rounded-md group-hover:bg-white group-hover:text-black mt-5 block w-max border border-purple-500'>Get The App!</a>
        </div>
      </div>
        <div className='flex items-center justify-end mt-5 font-semibold hover:text-blue-500'>
          <a href="/">Explore More Apps<i class="ri-arrow-right-line"></i></a>
        </div>
    </div>

    <div className='grid md:grid-cols-2 grid-cols-1 gap-10 mt-20 items-center'>
    <div>
      <img src={why} alt="" className=' rounded-lg w-full h-[23rem] object-cover' />
    </div>
    <div>
    <h2 className='text-3xl font-bold text-purple-500 mb-5'>Why Choose Our AI Solutions?</h2>
    <p className='text-sm'>Harness the power of cutting-edge AI technology to enhance your writing. Our tools provide intelligent suggestions, grammar corrections, and content improvement in real-time, ensuring that your work is polished and professional. Leverage the latest in artificial intelligence to enhance your writing. Our tools provide real-time feedback, content suggestions, and error correction to ensure your work is polished and professional.</p>
    <h2 className='text-xl font-bold text-blue-500 mt-5'>Tailored for Educational Excellence</h2>
    <ul className=' list-disc ml-5 mt-2'>
    <li className='text-xs'><b>Specialized for Education:</b> Our tools address the specific needs of educational institutions, enhancing both academic and operational efficiency.</li>
    <li className='text-xs mt-2'><b>User-Friendly Design:</b> Intuitive and easy to integrate into existing workflows, our solutions are designed with the end-user in mind.</li>
    <li className='text-xs mt-2'><b>Scalable and Adaptable:</b> Whether you’re a large university or a small language center, our tools are scalable to fit your unique requirements.</li>
    <li className='text-xs mt-2'><b>Commitment to Innovation:</b> We continually update and enhance our tools to stay aligned with the latest educational trends and technological advancements.</li>
    </ul>
    </div>
    
    </div>

    <div className='mt-20'>
    <h2 className='text-3xl font-bold text-purple-500'>News and Blogs</h2>
    <p className='text-sm mt-2 w-3/4'>Stay updated with the latest tips, trends, and insights in the world of writing. Explore our blog for expert advice, industry news, and more.</p>
    
    <div className=' grid md:grid-cols-4 grid-cols-1 gap-2 mt-5'>
    <div>
    <img src={b1} alt="" className='w-full h-48 rounded-md object-cover' />
    <p className='text-xs font-semibold mt-2'>August 25, 2024</p>
    <h3 className=' font-semibold'>10 Tips to Improve Your Writing Skills Today</h3>
    <p className='text-[10px]'>Discover practical tips and techniques that can help you enhance your writing skills. Whether you're a beginner or a seasoned writer, these strategies will take your writing to the next level.</p>
    <a href="/" className='text-sm text-blue-500'>Read More</a>
    </div>

    <div>
    <img src={b1} alt="" className='w-full h-48 rounded-md object-cover' />
    <p className='text-xs font-semibold mt-2'>August 25, 2024</p>
    <h3 className=' font-semibold'>10 Tips to Improve Your Writing Skills Today</h3>
    <p className='text-[10px]'>Discover practical tips and techniques that can help you enhance your writing skills. Whether you're a beginner or a seasoned writer, these strategies will take your writing to the next level.</p>
    <a href="/" className='text-sm text-blue-500'>Read More</a>
    </div>

    <div>
    <img src={b1} alt="" className='w-full h-48 rounded-md object-cover' />
    <p className='text-xs font-semibold mt-2'>August 25, 2024</p>
    <h3 className=' font-semibold'>10 Tips to Improve Your Writing Skills Today</h3>
    <p className='text-[10px]'>Discover practical tips and techniques that can help you enhance your writing skills. Whether you're a beginner or a seasoned writer, these strategies will take your writing to the next level.</p>
    <a href="/" className='text-sm text-blue-500'>Read More</a>
    </div>

    <div>
    <img src={b1} alt="" className='w-full h-48 rounded-md object-cover' />
    <p className='text-xs font-semibold mt-2'>August 25, 2024</p>
    <h3 className=' font-semibold'>10 Tips to Improve Your Writing Skills Today</h3>
    <p className='text-[10px]'>Discover practical tips and techniques that can help you enhance your writing skills. Whether you're a beginner or a seasoned writer, these strategies will take your writing to the next level.</p>
    <a href="/" className='text-sm text-blue-500'>Read More</a>
    </div>

    </div>
    <div className='flex items-center justify-end mt-5 font-semibold hover:text-blue-500'>
          <a href="/">Explore More Apps<i class="ri-arrow-right-line"></i></a>
        </div>
    </div>

    <div id='contact' className='mt-20'>
    <div className='grid md:grid-cols-2 grid-cols-1 gap-5'>
    <div>
    <h2 className='text-3xl font-bold text-purple-500'>Get in Touch with Us</h2>
    <p className='text-sm mt-2 w-3/4'>We’d love to hear from you! Whether you have questions, feedback, or need support, our team is here to help. Reach out to us through the contact form below or find our details for direct communication.</p>
    <h3 className='my-2 text-lg font-semibold'>Contact Info</h3>
    <p className=' font-semibold'>Email Us: <span className=' font-normal text-sm'>AppverseT@gmail.com</span></p>
    <p className=' font-semibold'>Call Us: <span className=' font-normal text-sm'>+1 9546083150</span></p>
    <p className=' font-semibold'>Address: <span className=' font-normal text-sm'>Appverse Tech LLC. United States</span></p>
    <h3 className='my-2 text-lg font-semibold'>Social Media</h3>
    <p>Follow Us:</p>
    <div className='flex items-center gap-2 mt-3'>
    <a href="/" className=' bg-purple-500 bg-opacity-25 border border-purple-500 w-10 h-10 flex items-center justify-center rounded-full hover:bg-opacity-100 hover:text-white'><i class="ri-facebook-fill"></i></a>
    <a href="/" className=' bg-purple-500 bg-opacity-25 border border-purple-500 w-10 h-10 flex items-center justify-center rounded-full hover:bg-opacity-100 hover:text-white'><i class="ri-twitter-fill"></i></a>
    <a href="/" className=' bg-purple-500 bg-opacity-25 border border-purple-500 w-10 h-10 flex items-center justify-center rounded-full hover:bg-opacity-100 hover:text-white'><i class="ri-linkedin-fill"></i></a>
    <a href="/" className=' bg-purple-500 bg-opacity-25 border border-purple-500 w-10 h-10 flex items-center justify-center rounded-full hover:bg-opacity-100 hover:text-white'><i class="ri-instagram-fill"></i></a>
    </div>
    </div>
    <div>
      <form action="" className='bg-slate-200 border border-slate-500 rounded-lg p-5'>
        <h3 className=' text-xl font-bold mb-4'>Send Us A Message</h3>
        <div className='flex flex-col mt-2'>
          <label htmlFor="name" className='text-sm font-semibold mb-1'>Full Name</label>
          <input className='w-full bg-slate-100 p-2 rounded' type="text" id='name' placeholder='Enter Your Full Name' />
        </div>
        <div className='flex flex-col mt-2'>
          <label htmlFor="email" className='text-sm font-semibold mb-1'>Email</label>
          <input className='w-full bg-slate-100 p-2 rounded' type="email" id='email' placeholder='Enter Your Full Name' />
        </div>
        <div className='flex flex-col mt-2'>
          <label htmlFor="phone" className='text-sm font-semibold mb-1'>Phone</label>
          <input className='w-full bg-slate-100 p-2 rounded' type="tell" id='phone' placeholder='Enter Your Full Name' />
        </div>
        <div className='flex flex-col mt-2'>
          <label htmlFor="message" className='text-sm font-semibold mb-1'>Message</label>
          <textarea name="message" id="message" className='w-full bg-slate-100 p-2 rounded' rows={'5'} placeholder='Type Your Message Here'></textarea>
        </div>
        <button className='w-full bg-gradient-to-tl to-blue-500 from-purple-500 p-2 rounded-md text-white mt-4'>Send Message</button>
      </form>
    </div>
    </div>
    </div>

      </main>

      <footer className='bg-white text-center p-3 mt-20'>
        <p className='text-slate-400'>© 2024 Appverse Tech Inc. All rights reserved.</p>
      </footer>
    </div>
  )
}

export default Home
