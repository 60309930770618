import React from 'react'
import Navbar from './components/Navbar'

const Contact = () => {
  return (
    <div className='bg-slate-100 text-black'>
        <Navbar/>
        <div className='bg-gradient-to-tl to-[#a855f730] from-[#3b82f630] -mt-12'>
        <div className='max-w-[920px] m-auto px-4 md:px-0 p-28'>
            <h1 className='text-5xl font-bold gradient-text'>Contact us</h1>
            <p className='mt-5 w-[80%]'>we’re dedicated to supporting educational institutions with cutting-edge AI applications that enhance efficiency, performance, and academic integrity. Our innovative tools are designed to meet the diverse needs of universities, colleges, and language centers.</p>
        </div>
        </div>
      <main className=' max-w-[920px] m-auto px-4 md:px-0'>
      <div id='contact' className='mt-20'>
    <div className='grid md:grid-cols-2 grid-cols-1 gap-5'>
    <div>
    <h2 className='text-3xl font-bold text-purple-500'>Get in Touch with Us</h2>
    <p className='text-sm mt-2 w-3/4'>We’d love to hear from you! Whether you have questions, feedback, or need support, our team is here to help. Reach out to us through the contact form below or find our details for direct communication.</p>
    <h3 className='my-2 text-lg font-semibold'>Contact Info</h3>
    <p className=' font-semibold'>Email Us: <span className=' font-normal text-sm'>AppverseT@gmail.com</span></p>
    <p className=' font-semibold'>Call Us: <span className=' font-normal text-sm'>+1 9546083150</span></p>
    <p className=' font-semibold'>Address: <span className=' font-normal text-sm'>Appverse Tech LLC. United States</span></p>
    <h3 className='my-2 text-lg font-semibold'>Social Media</h3>
    <p>Follow Us:</p>
    <div className='flex items-center gap-2 mt-3'>
    <a href="/" className=' bg-purple-500 bg-opacity-25 border border-purple-500 w-10 h-10 flex items-center justify-center rounded-full hover:bg-opacity-100 hover:text-white'><i class="ri-facebook-fill"></i></a>
    <a href="/" className=' bg-purple-500 bg-opacity-25 border border-purple-500 w-10 h-10 flex items-center justify-center rounded-full hover:bg-opacity-100 hover:text-white'><i class="ri-twitter-fill"></i></a>
    <a href="/" className=' bg-purple-500 bg-opacity-25 border border-purple-500 w-10 h-10 flex items-center justify-center rounded-full hover:bg-opacity-100 hover:text-white'><i class="ri-linkedin-fill"></i></a>
    <a href="/" className=' bg-purple-500 bg-opacity-25 border border-purple-500 w-10 h-10 flex items-center justify-center rounded-full hover:bg-opacity-100 hover:text-white'><i class="ri-instagram-fill"></i></a>
    </div>
    </div>
    <div>
      <form action="" className='bg-slate-200 border border-slate-500 rounded-lg p-5'>
        <h3 className=' text-xl font-bold mb-4'>Send Us A Message</h3>
        <div className='flex flex-col mt-2'>
          <label htmlFor="name" className='text-sm font-semibold mb-1'>Full Name</label>
          <input className='w-full bg-slate-100 p-2 rounded' type="text" id='name' placeholder='Enter Your Full Name' />
        </div>
        <div className='flex flex-col mt-2'>
          <label htmlFor="email" className='text-sm font-semibold mb-1'>Email</label>
          <input className='w-full bg-slate-100 p-2 rounded' type="email" id='email' placeholder='Enter Your Full Name' />
        </div>
        <div className='flex flex-col mt-2'>
          <label htmlFor="phone" className='text-sm font-semibold mb-1'>Phone</label>
          <input className='w-full bg-slate-100 p-2 rounded' type="tell" id='phone' placeholder='Enter Your Full Name' />
        </div>
        <div className='flex flex-col mt-2'>
          <label htmlFor="message" className='text-sm font-semibold mb-1'>Message</label>
          <textarea name="message" id="message" className='w-full bg-slate-100 p-2 rounded' rows={'5'} placeholder='Type Your Message Here'></textarea>
        </div>
        <button className='w-full bg-gradient-to-tl to-blue-500 from-purple-500 p-2 rounded-md text-white mt-4'>Send Message</button>
      </form>
    </div>
    </div>
    </div>
      </main>
      <footer className='bg-white text-center p-3 mt-20'>
        <p className='text-slate-400'>© 2024 Appverse Tech Inc. All rights reserved.</p>
      </footer>
    </div>
  )
}

export default Contact
