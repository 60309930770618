import React from 'react'
import Navbar from './components/Navbar'
import gr from './images/gr.webp'
import gr1 from './images/gr1.webp'
import ail1 from './images/ail1.webp'
import ail2 from './images/ail2.webp'

const Tools = () => {
  return (
    <div className='bg-slate-100 text-black'>
        <Navbar/>
        <div className='bg-gradient-to-tl to-[#a855f730] from-[#3b82f630] -mt-12'>
        <div className='max-w-[920px] m-auto px-4 md:px-0 p-28'>
            <h1 className='text-5xl font-bold gradient-text'>Our Tools & Apps</h1>
            <p className='mt-5 w-[80%]'>we’re dedicated to supporting educational institutions with cutting-edge AI applications that enhance efficiency, performance, and academic integrity. Our innovative tools are designed to meet the diverse needs of universities, colleges, and language centers.</p>
        </div>
        </div>
      <main className=' max-w-[920px] m-auto px-4 md:px-0'>
        <div className='mt-28'>
        <div className='bg-slate-200 h-96 mt-16 md:col-span-2 grid md:grid-cols-2 grid-cols-1 group border border-slate-400 hover:bg-gradient-to-tl from-blue-500 to-purple-500 hover:text-white p-4 rounded-lg'>
          <div className=' relative'>
          <img src={gr} alt="" className=' h-96 w-48 object-cover rounded-md border-2 border-slate-600 absolute bottom-10 left-10' />
          <img src={gr1} alt="" className=' h-96 w-48 object-cover rounded-md border-2 border-slate-600 absolute bottom-0 right-10' />
          </div>
          <div>
        <h3 className='text-lg font-bold mt-2'>AI Grammar Assistant App</h3>
        <p>Write and edit documents anywhere with our AI-powered Writing Assistant. Get real-time grammar checks, style suggestions, and content enhancement right on your mobile device. <br />
        <b>Ensure Accuracy:</b> Advanced grammar and style checks help produce polished, error-free text. <br />
        <b>Improve Writing Skills:</b> Identify and correct grammatical mistakes to enhance writing habits and language proficiency. <br />
        <b>Support Diverse Needs:</b> Suitable for both native and non-native speakers, ensuring high-quality written content.

        </p>
        <a href="https://apps.apple.com/us/app/ai-grammar-assistant/id6479434692" className='bg-purple-500 bg-opacity-15 py-2 px-4 font-semibold rounded-md group-hover:bg-white group-hover:text-black mt-5 block w-max border border-purple-500'>Get The App!</a>
          </div>
        </div>

        <div className='bg-slate-200 h-96 mt-28 md:col-span-2 grid md:grid-cols-2 grid-cols-1 group border border-slate-400 hover:bg-gradient-to-tl from-blue-500 to-purple-500 hover:text-white p-4 rounded-lg'>
          <div className=' relative'>
          <img src={ail1} alt="" className=' h-96 w-48 object-cover rounded-md border-2 border-slate-600 absolute bottom-10 left-10' />
          <img src={ail2} alt="" className=' h-96 w-48 object-cover rounded-md border-2 border-slate-600 absolute bottom-0 right-10' />
          </div>
          <div>
        <h3 className='text-lg font-bold mt-2'>AI Writer - Easy Text Creation App</h3>
        <p>The AI Writer- subscription base, available in 27 different languages giving
        global reach.</p>
        <p>
        <b>Simplify Content Creation:</b> Quickly generate high-quality text for academic papers, research articles, and educational materials with ease. <br />
<b>Boost Productivity:</b> Save time on content creation, allowing educators and students to focus more on critical academic activities. <br />
<b>Enhance Creativity:</b> Assist in brainstorming and developing content ideas, providing a creative boost for writing and course development.

        </p>
        <a href="https://apps.apple.com/us/app/ai-writer-easy-text-creation/id6470300071" className='bg-purple-500 bg-opacity-15 py-2 px-4 font-semibold rounded-md group-hover:bg-white group-hover:text-black mt-5 block w-max border border-purple-500'>Get The App!</a>
          </div>
        </div>
        </div>
      </main>
      <footer className='bg-white text-center p-3 mt-20'>
        <p className='text-slate-400'>© 2024 Appverse Tech Inc. All rights reserved.</p>
      </footer>
    </div>
  )
}

export default Tools
